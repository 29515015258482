<template>
 <div>
    <v-row no-gutters class="pa-2">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
          <v-card flat v-else>
          
                <v-row dense no-gutters class="pa-2"> 
                </v-row>
                <div id="PrintReport">
                  <table class="print_table md-font" >
                      <tr>
                          <th class="text-left">DRAW</th>
                          <th class="text-right">RESULT</th>
                      </tr>
                      <tr v-for="(item, index) in RESULTS.results" :key="index">
                          <td> {{item.game=="2DLOCAL"?"2D LIVE":item.game}} - {{$moment(item.date).format('MMM DD, YYYY') + ' ' + item.time}} </td>
                        
                          <td class="text-right">{{item.combination}}</td>
                      </tr>
                  </table>
                </div>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      to_menu: false,
        search:'',
        from_date:'',
        to_date: '',
        date_range:"",
        salesman:{},
        transactions:[],
        per_type:'Overall'
    }),
    created() {
      this.PING()
     this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        this.GET_RESULTS()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      RESULTS(){
        return  this.$store.state.results
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
     
    },
  }
</script>